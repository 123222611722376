import Step from "@/components/Step";
import ImageCard from "@/components/ImageCard";
import CardConnector from "@/components/CardConnector";

import image1 from "./assets/building-1.webp";
import image2 from "./assets/building-2.webp";
import image3 from "./assets/building-3.webp";
import image4 from "./assets/building-4.webp";

const NewProduct = () => {
  return (
    <div
      className="container mb-[100px] lg:mb-[150px] pt-[50px] px-4 lg:px-0"
      id="napomnit-mne"
      data-aos="fade-up"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div
          className="rounded-[30px] bg-blue-200 flex flex-col bg-center bg-cover bg-no-repeat justify-between min-h-[496px] lg:min-h-[750px] overflow-hidden"
          style={{
            backgroundImage: `url(${image1})`,
          }}
        >
          <Step number="03" name="Новый продукт" className="p-[25px]" />
          <div className="p-[5px] lg:p-[15px]">
            <div className="bg-white p-[20px] rounded-[20px]">
              <h3 className="mb-[15px]">
                Будьте среди первых, кто узнает о запуске сайта!
              </h3>
              <p className="leading-[150%] mb-[30px] lg:mb-[70px] max-w-[472px]">
                Оставьте свой адрес электронной почты, и мы пригласим вас
                присоединиться к эксклюзивному запуску нового продукта.{" "}
                <span className="text-secondary-dark font-semibold">
                  Будьте в центре событий!
                </span>
              </p>
              <a
                aria-label="Уведомите меня"
                href="http://t.me/urbouz_bot"
                target="_blank"
                rel="noopener noreferrer"
                className="btn-primary whitespace-nowrap text-sm lg:text-base px-6 py-2 lg:px-8 lg:py-4"
              >
                Уведомите меня
              </a>
            </div>
          </div>
        </div>
        <div className="hidden lg:grid grid-cols-2 gap-6">
          <div className="flex flex-col gap-6">
            <ImageCard src={image4} alt="building four" className="h-[308px]" />
            <ImageCard src={image1} alt="building one" className="h-[308px]" />
          </div>
          <div className="flex flex-col gap-6 justify-end">
            <ImageCard
              src={image3}
              alt="building three"
              className="h-[308px] relative"
            >
              <CardConnector className="left-[-25px] top-[60px]" />
              <CardConnector className="left-[-25px] bottom-[10px]" />
            </ImageCard>
            <ImageCard
              src={image2}
              alt="building two"
              className="h-[308px] relative"
            >
              <CardConnector className="left-[-25px] top-[60px]" />
            </ImageCard>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewProduct;
