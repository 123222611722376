import { useEffect } from 'react'
import AOS from 'aos'

import Intro from './containers/Intro'
import JoinUs from './containers/JoinUs'
import NewProduct from './containers/NewProduct'
import OurMission from './containers/OurMission'
import StandOut from './containers/StandOut'

import 'aos/dist/aos.css'

function App() {
  useEffect(() => {
    AOS.init({ once: true, duration: 1000, offset: 200 })
  }, [])

  return (
    <div className="relative">
      <div className="hidden lg:block">
        <div className="grid z-[-1] grid-cols-3 w-full max-w-[1184px] absolute left-[calc(50%-592px)] top-0 h-full">
          <div className="border-l border-[#EEEFF2] h-full"></div>
          <div className="border-x custom-animate-bounce border-dashed border-[#EEEFF2] h-full"></div>
          <div className="border-r border-[#EEEFF2] h-full"></div>
        </div>
      </div>
      <Intro />
      <OurMission />
      <StandOut />
      <NewProduct />
      <JoinUs />
    </div>
  )
}

export default App
