import CardConnector from '@/components/CardConnector'
import Logo from '@/components/Logo'
import Step from '@/components/Step'
import ImageCard from '@/components/ImageCard'
import Description from './components/Description'

import image1 from '../NewProduct/assets/building-1.webp'
import image2 from '../NewProduct/assets/building-2.webp'
import image3 from '../NewProduct/assets/building-3.webp'
import image4 from '../NewProduct/assets/building-4.webp'

const OurMission = () => {
  return (
    <div className="container mb-[50px] px-4 lg:px-0 lg:mb-[150px]">
      <div
        className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] lg:gap-0 mb-9 lg:mb-16"
        data-aos="fade-up"
      >
        <div className="flex flex-col gap-[10px] lg:gap-[15px]">
          <Step number="01" name="Наша миссия" />
          <h1>Наша миссия</h1>
        </div>
        <div className="flex flex-col gap-[10px] lg:gap-[25px]">
          <Logo />
          <p className="[&_span]:text-secondary-dark [&_span]:font-semibold">
            <span>Добро пожаловать на Urbo.uz</span>, где мы открываем для вас
            двери к <span>уникальному миру</span> недвижимости в{' '}
            <span>Узбекистане</span>. Наша миссия – сделать поиск и сделки с
            недвижимостью максимально удобными, безопасными и захватывающими.”
          </p>
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] lg:gap-6">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] lg:gap-6">
          <div className="flex flex-col gap-6" data-aos="fade-up">
            <ImageCard src={image1} alt="building one" className="h-[341px]" />
            <Description
              text="Безопасность вашей сделки – наш приоритет."
              value="100K+"
              className="block lg:hidden"
            >
              <CardConnector className="top-[-43px] rotate-90 right-[25vw]" />
            </Description>
            <Description
              text="Оцените стоимость ипотечного кредита с помощью нашего"
              value="210 млн"
              className="hidden lg:flex min-h-[252px]"
            >
              <CardConnector className="bottom-[60px] right-[-25px]" />
            </Description>
          </div>
          <div
            className="flex flex-col lg:flex-col-reverse gap-6 lg:mt-[150px]"
            data-aos="fade-up"
          >
            <ImageCard src={image2} alt="building two" className="h-[341px]" />
            <Description
              text="Оцените стоимость ипотечного кредита с помощью нашего"
              value="210 млн"
              className="block lg:hidden"
            >
              <CardConnector className="top-[-43px] rotate-90 left-[25vw]" />
            </Description>
            <Description
              text=" Безопасность вашей сделки – наш приоритет."
              value="100K+"
              className="hidden lg:flex min-h-[252px]"
            >
              <CardConnector className="top-[80px] left-[-25px]" />
            </Description>
          </div>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-[30px] lg:gap-6">
          <div className="flex flex-col gap-6" data-aos="fade-up">
            <ImageCard
              src={image3}
              alt="building three"
              className="h-[341px]"
            />
            <Description
              text="Cредняя цена на новостройки за кв.м"
              value="$1000+"
              className="block lg:hidden"
            >
              <CardConnector className="top-[-43px] right-[25vw] rotate-90" />
            </Description>
            <Description
              text="Пользователей ежедневно ищут квартиры"
              value="90%"
              className="hidden lg:flex min-h-[252px]"
            >
              <CardConnector className="bottom-[20px] right-[-25px]" />
            </Description>
          </div>
          <div
            className="flex flex-col lg:flex-col-reverse gap-6 lg:mt-[100px] lg:mb-[100px]"
            data-aos="fade-up"
          >
            <ImageCard src={image4} alt="building four" className="h-[341px]" />
            <Description
              text="Пользователей ежедневно ищут квартиры"
              value="90%"
              className="block lg:hidden"
            >
              <CardConnector className="top-[-43px] left-[25vw] rotate-90" />
            </Description>
            <Description
              text="Cредняя цена на новостройки за кв.м"
              value="$1000+"
              className="hidden lg:flex min-h-[206px]"
            >
              <CardConnector className="top-[20px] left-[-25px]" />
            </Description>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OurMission
