import { IStandOut } from '@/types'
import smartSearch from '../containers/StandOut/assets/smart-search.webp'
import trustedSales from '../containers/StandOut/assets/trusted-sales.webp'
import regionAnalytics from '../containers/StandOut/assets/region-analytics.webp'
import exclusiveOffer from '../containers/StandOut/assets/exclusive-offer.webp'
import calculator from '../containers/StandOut/assets/calculator.webp'
import mortgage from '../containers/StandOut/assets/mortgage.webp'

export const standOuts: IStandOut[] = [
  {
    title: '<span>Умный поиск</span> с отбором по критериям',
    description:
      'Urbo.uz предлагает вам не просто объявления – мы предлагаем решения. Наш умный поиск учтет каждый ваш критерий, чтобы вы нашли идеальное жилье, соответствующее вашим ожиданиям.',
    image: smartSearch,
  },
  {
    title: 'Безопасные и <span>проверенные сделки</span>',
    description:
      'Безопасность вашей сделки – наш приоритет. Мы тщательно проверяем каждый объект нашей базы данных, чтобы гарантировать честность и законность. Сделайте шаг в будущее с Urbo.uz.',
    image: trustedSales,
  },
  {
    title: 'Интерактивные карты и <span>аналитика района</span>',
    description:
      'Откройте для себя окрестности будущего дома благодаря нашим детальным картам. Мы предоставляем вам всю необходимую информацию – от школ и магазинов до транспортной инфраструктуры.',
    image: regionAnalytics,
  },
  {
    title: '<span>Эксклюзивные</span> предложения',
    description:
      'Urbo.uz – это не просто площадка для объявлений. Мы предлагаем вам уникальные предложения, недоступные на других ресурсах. С нами вы первыми увидите самые перспективные варианты на рынке.',
    image: exclusiveOffer,
  },
  {
    title: 'Ипотечный <span>калькулятор</span>',
    description:
      'Оцените стоимость ипотечного кредита с помощью нашего удобного калькулятора. Введите необходимые данные, и мы предоставим вам расчет платежей, срока кредита и другие важные детали.',
    image: calculator,
  },
  {
    title: '<span>Ипотека</span> это просто',
    description:
      'Urbo.uz предлагает вам не просто объявления – мы предлагаем решения. Наш умный поиск учтет каждый ваш критерий, чтобы вы нашли идеальное жилье, соответствующее вашим ожиданиям.',
    image: mortgage,
  },
]
