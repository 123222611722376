const StarIcon = (props: any) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path
        d="M12 0C5.37067 0 0 5.37067 0 12C0 18.6293 5.37067 24 12 24C18.6293 24 24 18.6293 24 12C24 5.37067 18.6293 0 12 0ZM19.5307 10.64L15.792 14.1653L16.9334 18.256C17.1627 19.0827 16.7574 19.3974 16.016 18.9547L12 16.5334L7.97869 18.9493C7.24266 19.392 6.8107 19.0773 7.01867 18.24L8.12267 13.792L4.50132 10.5974C3.856 10.0267 4.03198 9.56801 4.89067 9.56801H9.29066L11.3813 4.70402C11.7226 3.91467 12.2666 3.91467 12.5973 4.70933L14.64 9.56801H19.0986C19.9627 9.57336 20.1547 10.0533 19.5307 10.64Z"
        fill="currentColor"
      />
    </svg>
  )
}

export default StarIcon
