import { FC } from 'react'

interface IProps {
  number: string
  name: string
  className?: string
}

const Step: FC<IProps> = ({ number, name, className = '' }) => {
  return (
    <div className={`flex flex-row gap-3 text-secondary-dark ${className}`}>
      <span className="btn font-semibold hover:bg-inherit hover:border-secondary-dark text-sm lg:text-base max-h-[38px] max-w-[38px] lg:max-h-[50px] lg:max-w-[50px] flex justify-center items-center">
        {number}
      </span>
      <span className="btn max-h-[38px] hover:bg-inherit hover:border-secondary-dark text-sm lg:text-base lg:max-h-[50px] flex justify-center items-center font-semibold">
        {name}
      </span>
    </div>
  )
}

export default Step
