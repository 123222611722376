import Footer from "@/components/Footer";
import image from "./assets/join-us-image.svg";

const JoinUs = () => {
  return (
    <div className="bg-[#171620]">
      <div className="container px-4 lg:px-0">
        <div className="grid gap-[50px] lg:gap-0 grid-cols-1 lg:grid-cols-2 py-[56px] lg:py-[150px] items-center">
          <img
            src={image}
            alt="join us chat image"
            className="order-2 lg:order-1 w-full lg:w-fit"
          />
          <div className="flex flex-col order-1 lg:order-2">
            <h1 className="text-white mb-[15px]">
              Присоединяйтесь к нам сегодня!
            </h1>
            <p className="mb-[35px] text-white font-medium">
              Urbo.uz – ваш надежный гид в мире недвижимости Узбекистана.
              Начните свой поиск или разместите объявление прямо сейчас!
            </p>
            <a
              href="https://t.me/urbouz_bot"
              className="btn-primary w-fit px-8 py-4"
              aria-label="Уведомите меня"
              target="_blank"
              rel="noopener noreferrer"
            >
              Уведомите меня
            </a>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
};

export default JoinUs;
