import Header from '@/components/Header'

import StarIcon from './components/StarIcon'
import imageUrl1 from './assets/sky.webp'
import imageUrl2 from './assets/building.webp'

const features = [
  'Мы открываем для вас двери к уникальному миру недвижимости в Узбекистане.',
  'Поиск и сделки с недвижимостью максимально удобные, безопасные и захватывающие.',
]

const Intro = () => {
  return (
    <div className="p-4 lg:p-[20px] bg-white lg:pb-0 pb-0 mb-[100px] lg:mb-[150px]">
      <div
        className="bg-blue-200 rounded-[50px] bg-no-repeat bg-[position:right_bottom,_center] bg-[size:115%,_cover] 2xl:bg-[size:50%,_cover] lg:bg-[size:65%,_cover]"
        style={{
          backgroundImage: `url(${imageUrl2}), url(${imageUrl1})`,
          backgroundRepeat: 'no-repeat, no-repeat',
        }}
      >
        <div className="container p-6 lg:pt-8 pb-[400px] lg:pb-[100px]">
          <Header />
          <div className="mt-[50px] lg:mt-[135px] w-full lg:w-[65%]">
            <h1 className="mb-[30px] lg:mb-[190px]">
              Urbo.uz – новый этап в мире недвижимости Узбекистана!
            </h1>
            <ul className="flex flex-col lg:flex-row gap-[30px] lg:gap-[25px]">
              {features.map((val, i) => (
                <li
                  key={val}
                  className="text-secondary-dark flex flex-col max-w-[280px] gap-[15px]"
                  data-aos="fade-up"
                  data-aos-offset="0"
                >
                  <StarIcon
                    className={
                      i + 1 === 1 ? 'star-animation-1' : 'star-animation-2'
                    }
                  />
                  <span className=" font-medium leading-[150%]">{val}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}
export default Intro
