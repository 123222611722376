import { useState, useEffect } from 'react'
import Logo from './Logo'

const routes = [
  { name: 'Для кого', path: '#dlya-kogo' },
  { name: 'О проекте', path: '#o-proyekte' },
  { name: 'Что нас выделяет', path: '#chto-nas-videlyaet' },
  { name: 'Напомнить мне!', path: '#napomnit-mne', primary: true },
]

const Header = () => {
  const [isBurgerOpen, setIsBurgerOpen] = useState(false)

  useEffect(() => {
    const handleScrollLock = () => {
      if (isBurgerOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    }

    handleScrollLock()

    window.addEventListener('resize', handleScrollLock)

    return () => {
      window.removeEventListener('resize', handleScrollLock)
    }
  }, [isBurgerOpen])

  return (
    <header className="flex flex-row justify-between items-start lg:items-center">
      <Logo />
      <button
        className={`z-[31] block fixed right-10 top-10 lg:hidden duration-300 ${
          isBurgerOpen ? 'rotate-45 ' : ''
        }`}
        aria-label="open the burger menu"
        onClick={() => setIsBurgerOpen((prev) => !prev)}
      >
        <MenuIcon />
      </button>
      <ul
        className={`flex z-30 lg:z-0 fixed lg:relative top-0 pt-[100px] p-[42px] lg:p-0 left-0 bg-white lg:bg-transparent right-0 bottom-0 flex-col duration-300 lg:flex-row gap-4 items-end lg:items-center ${
          isBurgerOpen ? '' : ' translate-x-[-1000px] lg:translate-x-0'
        }`}
      >
        {routes.map((val) => (
          <li key={val.name}>
            <a
              aria-label={val.name}
              href={val.path}
              className={val.primary ? 'btn-primary' : 'btn'}
              onClick={() => setIsBurgerOpen(false)}
            >
              {val.name}
            </a>
          </li>
        ))}
      </ul>
    </header>
  )
}

export default Header

const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
    >
      <circle cx="18" cy="18" r="17.5" stroke="#262527" />
      <path
        d="M22 24V26H11V24H22ZM27 17V19H9V17H27ZM25 10V12H14V10H25Z"
        fill="black"
      />
    </svg>
  )
}
