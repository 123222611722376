import { useEffect, useState } from "react";

import { API_URL } from "@/api";
import type { IPreference } from "@/types";

const Footer = () => {
  const [preferences, setPreferences] = useState<IPreference[] | null>(null);

  useEffect(() => {
    const fetchPreferences = async () => {
      setPreferences(null);
      try {
        const response = await fetch(API_URL + "/preferences/");
        if (response.ok) {
          const data = await response.json();
          setPreferences(data);
        } else {
          console.error("Failed to fetch preferences:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching preferences:", error);
      }
    };

    fetchPreferences();
  }, []);

  const email = preferences?.find((val) => val.key === "email");
  const phone = preferences?.find((val) => val.key === "phone");
  const instagram = preferences?.find((val) => val.key === "instagram");
  const telegram = preferences?.find((val) => val.key === "telegram");
  const facebook = preferences?.find((val) => val.key === "facebook");

  const routes = {
    contact: [
      { name: email?.value, path: `mailto:${email?.value}` },
      { name: phone?.value, path: `tel:${phone?.value}` },
    ],
    socials: [
      { name: "Instagram", path: instagram?.value },
      { name: "Telegram", path: telegram?.value },
      { name: "Facebook", path: facebook?.value },
    ],
  };

  return (
    <footer className="flex flex-col gap-[20px] lg:gap-0 lg:flex-row justify-between items-center py-[60px] border-t border-secondary-dark">
      <div className="btn text-white text-sm lg:text-base hover:bg-inherit hover:border-inherit">
        © 2024
      </div>
      <ul className="flex flex-row gap-4">
        {routes.contact.map((val, i) => (
          <li key={val.name + "footer-item-" + i}>
            <a
              aria-label={val.name}
              href={val.path}
              className="btn text-white text-sm lg:text-base whitespace-nowrap hover:text-secondary-dark"
            >
              {val.name}
            </a>
          </li>
        ))}
      </ul>
      <ul className="flex flex-row gap-4">
        {routes.socials.map((val) => (
          <li key={val.name}>
            <a
              aria-label={val.name}
              href={val.path}
              className="btn text-white text-sm lg:text-base whitespace-nowrap hover:text-secondary-dark"
            >
              {val.name}
            </a>
          </li>
        ))}
      </ul>
    </footer>
  );
};

export default Footer;
