import { FC, HTMLAttributes } from 'react'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  text: string
  value: string
}

const Description: FC<IProps> = (props) => {
  return (
    <div
      {...props}
      className={`bg-[#eeeff2] flex flex-col relative justify-between rounded-[20px] p-6 lg:p-[15px] ${props.className}`}
    >
      <p className="text-base text-secondary-dark mb-6 lg:mb-0">{props.text}</p>
      <h2 className="text-2xl lg:text-[42px] lg:text-end">{props.value}</h2>
      {props.children}
    </div>
  )
}

export default Description
