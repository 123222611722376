import { FC, HTMLAttributes } from 'react'

interface IProps extends HTMLAttributes<HTMLDivElement> {}

const CardConnector: FC<IProps> = (props) => {
  return (
    <div
      {...props}
      className={`absolute min-h-[62px] min-w-[26px] bg-[#eeeff2] ${props.className}`}
    >
      <div className="bg-white border-none rounded-b-full min-h-[24px] min-w-[24px] top-[-10px] left-[1px] absolute"></div>
      <div className="bg-white border-none rounded-t-full min-h-[24px] min-w-[24px] top-[50px] left-[1px] absolute"></div>
    </div>
  )
}

export default CardConnector
