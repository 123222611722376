import Step from '@/components/Step'
import { standOuts } from '@/mock'

const StandOut = () => {
  const isEvenOrOdd = (number: number) =>
    number % 2 === 0 ? 'lg:order-1' : 'lg:order-2'

  return (
    <div
      className="container mb-[100px] pt-[50px] px-4 lg:px-0"
      id="chto-nas-videlyaet"
    >
      <div
        className="flex justify-center flex-col items-center gap-[15px]"
        data-aos="fade-up"
      >
        <Step number="02" name="Что нас выделяет?" />
        <h1 className="max-w-[580px] text-center mb-[50px]">
          Ваш дом - ваше отражение
        </h1>
      </div>
      <div className="flex flex-col gap-10">
        {standOuts.map((val, i) => (
          <div
          key={val.title + i}
            className="grid grid-cols-1 lg:grid-cols-2 items-center"
            data-aos="fade-up"
          >
            <div
              className={`flex flex-col max-w-[502px] gap-[15px] ${isEvenOrOdd(
                i + 2
              )}`}
            >
              <h2
                className="[&_span]:text-primary leading-[1.2]"
                dangerouslySetInnerHTML={{ __html: val.title }}
              />
              <p className=" font-medium">{val.description}</p>
            </div>
            <div
              className={`min-h-[450px] h-full relative ${isEvenOrOdd(i + 1)}`}
            >
              <img
                src={val.image}
                className="h-full w-full absolute lg:min-h-[700px] lg:-translate-y-[calc(65%-350px)] object-contain left-0 lg:right-0 top-0"
                alt=""
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default StandOut
