import { FC, HTMLAttributes } from 'react'

interface IProps extends HTMLAttributes<HTMLDivElement> {
  src: string
  alt: string
}

const ImageCard: FC<IProps> = (props) => {
  const src = props.src

  return (
    <div
      {...props}
      className={`bg-[#eeeff2] rounded-[20px] p-[10px] ${props.className}`}
    >
      <img
        src={src}
        alt={props.alt}
        className="object-cover h-full w-full rounded-2xl"
      />
      {props.children}
    </div>
  )
}

export default ImageCard
